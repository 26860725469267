import React from 'react'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import { styled } from '@mui/material/styles'

// Styled button component for the "Create New" action
const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 16px',
  gap: '8px',
  width: 'auto',
  height: '36px',
  backgroundColor: '#EA335F',
  borderRadius: '4px',
  color: '#FFFFFF',
  fontSize: '14px',
  lineHeight: '140%',
  textTransform: 'none',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '#D32F50',
  },
}))

// Props interface for CreateHookButton
export type CreateHookButtonProps = {
  onClick?: () => void // Optional onClick handler for button action
}

// Button component for creating a new hook or item
const CreateHookButton: React.FC<CreateHookButtonProps> = ({ onClick }) => {
  return (
    <StyledButton
      variant="contained"
      startIcon={<AddIcon style={{ width: '24px', height: '24px' }} />}
      onClick={onClick}
    >
      Create New
    </StyledButton>
  )
}

export default CreateHookButton
