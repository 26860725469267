import React from 'react'
import { Box } from '@mui/material'
import { Android12Switch } from '../popups/CreateOfferModal'

export const OfferStatusRenderer = (props: any) => {
  return (
    <Box
      sx={{
        marginLeft: '-0.5em',
        cursor: 'default'
      }}
    >
      <Android12Switch
        checked={props.row.active}
        disabled={true}
      />
    </Box>
  )
}
