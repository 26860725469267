import React, { useState, useEffect } from 'react'
import {
  Stack,
  TextField,
  Typography,
  Breadcrumbs,
  Link,
  Button,
  Divider,
} from '@mui/material'
import { styled } from '@mui/system'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import CustomSwitch from './CustomSwitch'
import { newColor } from 'src/consts/colors'

// Styled Save button component
const StyledSaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#EA335F',
  color: 'white',
  borderRadius: '4px',
  padding: '8px 16px',
  '&:hover': {
    backgroundColor: '#E91E63',
  },
}))

// Cancel button styling
const StyledCancelButton = styled(Button)(({ theme }) => ({
  color: '#FFFFFF',
  padding: '8px 16px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
}))

interface CreateRedemptionCodePageProps {
  onSave: (redemptionCodeData: any) => void
  onBack: () => void
  campaignName: string
  campaignId: number
  existingCodeData?: any
}

const CreateRedemptionCodePage: React.FC<CreateRedemptionCodePageProps> = ({
  onSave,
  onBack,
  campaignName,
  campaignId,
  existingCodeData,
}) => {
  const [code, setCode] = useState('')
  const [name, setName] = useState('')
  const [rootUrl, setRootUrl] = useState('')
  const [usageLimit, setUsageLimit] = useState(1)
  const [status, setStatus] = useState(true)
  const [jsonData, setJsonData] = useState('{}')
  const [isReferral, setIsReferral] = useState(true)

  // Pre-fill form when editing
  useEffect(() => {
    if (existingCodeData) {
      setCode(existingCodeData.code || '')
      setName(existingCodeData.name || '')
      setRootUrl(existingCodeData.base_url || '')
      setUsageLimit(existingCodeData.number_of_usage || 1)
      setStatus(existingCodeData.status === 'active')
      setJsonData(JSON.stringify(existingCodeData.rewards, null, 2) || '[]')
      setIsReferral(existingCodeData.type === 'referral')
    }
  }, [existingCodeData])

  // Handle saving redemption code
  const handleSave = () => {
    try {
      let rewards

      // Try parsing JSON data
      try {
        rewards = JSON.parse(jsonData)
        // If it's a single object, wrap it in an array
        if (!Array.isArray(rewards)) {
          rewards = [rewards]
        }
      } catch (error) {
        // Fallback to manually wrapping data if it's not valid JSON
        rewards = JSON.parse(`[${jsonData}]`)
      }

      const newRedemptionCode = {
        campaign_id: campaignId,
        name: name,
        code: code,
        base_url: rootUrl,
        type: isReferral ? 'referral' : 'normal',
        number_of_usage: usageLimit,
        status: status ? 'active' : 'inactive',
        rewards: rewards,
      }

      onSave(newRedemptionCode)
    } catch (error) {
      console.error('Error parsing jsonData or saving redemption code:', error)
    }
  }

  return (
    <Stack
      direction="column"
      gap={2}
      sx={{
        maxWidth: { xs: '100%', md: '90%' },
        width: '100%',
        height: '100%',
        position: 'relative',
        margin: '0px auto',
        padding: 3,
      }}
    >
      {/* Breadcrumbs and Back Button */}
      <Stack
        direction="column"
        sx={{ width: '100%', maxWidth: '600px', alignItems: 'flex-start' }}
      >
        <Breadcrumbs aria-label="breadcrumb" separator=" / ">
          <Typography color="text.primary" sx={{ color: '#FFFFFF' }}>
            Redemption Codes
          </Typography>
          <Link
            component="button"
            underline="hover"
            color="inherit"
            onClick={onBack}
          >
            Campaigns
          </Link>
          <Typography color="text.primary" sx={{ color: '#FFFFFF' }}>
            {campaignName}
          </Typography>
        </Breadcrumbs>

        <Typography fontSize={35}>
          {existingCodeData
            ? 'Edit Redemption Code'
            : 'Create New Redemption Code'}
        </Typography>

        <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
          <Button onClick={onBack} sx={{ color: newColor.white }}>
            <ArrowBackIosIcon sx={{ color: newColor.white }} /> Redemption Codes
          </Button>
        </Stack>
      </Stack>

      {/* Form Fields */}
      <Stack
        spacing={4}
        sx={{ width: '100%', maxWidth: '600px', alignSelf: 'center' }}
      >
        <Typography sx={{ color: newColor.white, mb: 1 }}>Code</Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={code}
          onChange={(e) => setCode(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: '#1C2025',
              borderColor: '#434D5A',
              color: '#FFFFFF',
              borderRadius: '4px',
            },
          }}
        />

        <Typography sx={{ color: newColor.white, mb: 1 }}>
          Name (Optional)
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={name}
          placeholder="Add a descriptive name for the code"
          onChange={(e) => setName(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: '#1C2025',
              borderColor: '#434D5A',
              color: '#FFFFFF',
              borderRadius: '4px',
            },
          }}
        />

        <Typography sx={{ color: newColor.white, mb: 1 }}>Root URL</Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={rootUrl}
          onChange={(e) => setRootUrl(e.target.value)}
          InputProps={{
            sx: {
              backgroundColor: '#1C2025',
              borderColor: '#434D5A',
              color: '#FFFFFF',
              borderRadius: '4px',
            },
          }}
        />

        <Typography sx={{ color: newColor.white, mb: 1 }}>Code URL</Typography>
        <TextField
          variant="outlined"
          fullWidth
          value={`${rootUrl}?code=${code}`}
          InputProps={{
            sx: {
              backgroundColor: '#141414',
              padding: '8px 16px',
              borderRadius: '4px',
              color: '#FFFFFF',
              height: '44px',
              alignItems: 'center',
              '& .Mui-disabled': {
                color: '#FFFFFF',
                WebkitTextFillColor: '#FFFFFF',
              },
            },
            disabled: true,
            endAdornment: (
              <Button
                onClick={() =>
                  navigator.clipboard.writeText(`${rootUrl}?code=${code}`)
                }
              >
                <ContentCopyIcon sx={{ color: '#FFFFFF' }} />
              </Button>
            ),
          }}
        />

        <Typography sx={{ color: newColor.white, mb: 1 }}>
          Usage Limit
        </Typography>
        <TextField
          variant="outlined"
          fullWidth
          type="number"
          sx={{ maxWidth: '100px' }}
          value={usageLimit}
          onChange={(e) => setUsageLimit(Number(e.target.value))}
          InputProps={{
            sx: {
              backgroundColor: '#1C2025',
              borderColor: '#434D5A',
              color: '#FFFFFF',
              borderRadius: '4px',
            },
          }}
        />

        {/* Status Switch */}
        <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
          <CustomSwitch checked={status} onChange={() => setStatus(!status)} />
          <Typography
            sx={{ ml: 2 }}
          >{`Code status is ${status ? 'Active' : 'Inactive'}`}</Typography>
        </Stack>

        {/* JSON Field */}
        <Stack sx={{ mt: 2 }}>
          <Typography sx={{ color: newColor.white, mb: 1 }}>Rewards</Typography>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            value={jsonData}
            onChange={(e) => setJsonData(e.target.value)}
            InputProps={{
              sx: {
                backgroundColor: '#1C2025',
                borderColor: '#434D5A',
                color: '#FFFFFF',
                borderRadius: '4px',
              },
            }}
          />
        </Stack>

        {/* Referral Switch */}
        <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
          <CustomSwitch
            checked={isReferral}
            onChange={() => setIsReferral(!isReferral)}
          />
          <Typography sx={{ ml: 2 }}>Use as referral code</Typography>
        </Stack>

        {/* Divider */}
        <Divider sx={{ my: 4, backgroundColor: '#434D5A', height: '1px' }} />

        {/* Action Buttons */}
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <StyledCancelButton onClick={onBack}>Cancel</StyledCancelButton>
          <StyledSaveButton onClick={handleSave}>Save</StyledSaveButton>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CreateRedemptionCodePage
