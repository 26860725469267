import React, { useState } from 'react'
import {
  MenuItem,
  Select,
  Button,
  Typography,
  Popover,
  Stack,
  IconButton,
} from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'

// Styled button for the sort control
const CustomButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px',
  width: 'auto',
  minWidth: '91px',
  height: '36px',
  boxSizing: 'border-box',
  border: '1px solid #434D5A',
  borderRadius: '4px',
  background: '#000000',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#333333',
  },
}))

// Styled popover container for the sorting options
const PopoverContainer = styled(Popover)(({ theme }) => ({
  '.MuiPopover-paper': {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 0px',
    width: '300px',
    background: '#1C1C1C',
    boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
    border: 'none',
  },
}))

// Container for sort controls inside the popover
const SortContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 16px',
  gap: '12px',
  width: '100%',
  background: '#1C1C1C',
}))

// Row layout for sort controls
const SortRow = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  justifyContent: 'space-between',
}))

// Styled select field for sorting criteria
const SelectField = styled(Select)(({ theme }) => ({
  backgroundColor: '#1C2025',
  border: '1px solid #434D5A',
  borderRadius: '4px',
  color: '#FFFFFF',
  height: '36px',
  padding: '8px 12px',
  width: '180px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '140%',
}))

// Custom SVG icon for toggling sort order
const SVGSortIcon = ({ sortOrder }: { sortOrder: 'asc' | 'desc' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: sortOrder === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
      transition: 'transform 0.3s ease',
    }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 5L3 10.0782H6V19H8V10.0782H11L7 5ZM15.3333 18H12V16H15.3333V18ZM12 8V6H22V8H12ZM18.6667 13H12V11H18.6667V13Z"
      fill="#D2D2D3"
    />
  </svg>
)

// SortButton component with sorting options and toggle for ascending/descending order
const SortButton = ({
  sortField,
  sortOrder,
  onSortChange,
}: {
  sortField: string
  sortOrder: 'asc' | 'desc'
  onSortChange: (field: string, order: 'asc' | 'desc') => void
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'sort-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Toggles between ascending and descending order
  const handleSortOrderToggle = () => {
    onSortChange(sortField, sortOrder === 'asc' ? 'desc' : 'asc')
  }

  return (
    <>
      <CustomButton onClick={handleClick}>
        <SwapVertIcon sx={{ color: '#D2D2D3' }} />
        <Typography
          sx={{
            fontSize: '14px',
            color: '#FFFFFF',
            paddingLeft: '8px',
            paddingRight: '8px',
            whiteSpace: 'nowrap',
          }}
        >
          Sort
        </Typography>
      </CustomButton>

      <PopoverContainer
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SortContainer>
          <SortRow>
            <SelectField
              value={sortField}
              onChange={(e) =>
                onSortChange(e.target.value as string, sortOrder)
              }
            >
              <MenuItem value="updatedAt">Last updated</MenuItem>
              <MenuItem value="startDate">Created date</MenuItem>
              <MenuItem value="name">Alphabetical</MenuItem>
            </SelectField>

            <IconButton onClick={handleSortOrderToggle}>
              <SVGSortIcon sortOrder={sortOrder} />
            </IconButton>

            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: '#FFFFFF' }} />
            </IconButton>
          </SortRow>
        </SortContainer>
      </PopoverContainer>
    </>
  )
}

export default SortButton
