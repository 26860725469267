import React from 'react'
import EllipsisIcon from 'src/assets/vertical_ellipsis_icon.svg'
import { Menu } from 'helika-ui-sdk'
import _ from 'lodash'

export const DefaultActionMenuRenderer = (props: any) => {

  let defaultMenuItem = [{}]

  let menuItems = !_.isEmpty(props.colDef?.slotProps?.menuItems)
    ? props.colDef?.slotProps?.menuItems
    : defaultMenuItem


  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        maxWidth: '30em',
        margin: 'auto',
        gap: '5px',
        width: '100%',
        cursor: 'pointer'
      }}
    >
      <Menu
        setOpen={() => { }}
        title={''}
        sxProps={{}}
        disabled={false}
        darkMode={true}
        menuItems={menuItems}
        sxPropsMenu={{
        }}
        sxPropsMenuButton={{
          color: 'white',
          background: 'transparent'
        }}
        buttonComponent={
          <img
            src={EllipsisIcon}
            alt="menu"
            style={{ margin: 'auto' }}
          />
        }
      />
    </div>
  )
}
