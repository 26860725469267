import React from 'react'
import { Stack, Typography, Snackbar, Slide, Button } from '@mui/material'
import { styled } from '@mui/system'
import InboxIcon from '@mui/icons-material/Inbox'
import { newColor } from 'src/consts/colors'

// Wrapper for the Floating Action Bar component
const FloatingActionBarWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 20px',
  position: 'fixed',
  bottom: '20px',
  width: '850px',
  height: '68px',
  backgroundColor: '#1C1C1C',
  border: '1px solid #373737',
  boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px',
  zIndex: 1000,
})

// Typography for displaying the number of selected items
const SelectedText = styled(Typography)({
  fontSize: '16px',
  lineHeight: '150%',
  color: '#FFFFFF',
  flexGrow: 1,
  textAlign: 'left',
})

// Container for the action buttons
const ActionsWrapper = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px',
})

// Styled button for the "Archive" action
const ArchiveButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  color: newColor.white,
  border: `1px solid #434D5A`,
  borderRadius: '4px',
  padding: '8px 16px',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: newColor.dimGray,
  },
})

// New button for "Update usage limit"
const UpdateButton = styled(Button)({
  backgroundColor: newColor.jazzberryJam,
  color: '#FFFFFF',
  borderRadius: '4px',
  padding: '8px 16px',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#E91E63',
  },
})

// Floating Action Bar component with bulk actions
const FloatingActionBarComponent: React.FC<{
  selectedCodesLength: number
  handleBulkArchive: () => void
  handleUnselectAll: () => void
  open: boolean
  isLoading: boolean
  showUpdateButton?: boolean
  handleUpdateUsageLimit?: () => void
}> = ({
  selectedCodesLength,
  handleBulkArchive,
  handleUnselectAll,
  open,
  isLoading,
  showUpdateButton,
  handleUpdateUsageLimit,
}) => (
  <Snackbar
    open={open}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    TransitionComponent={(props) => <Slide {...props} direction="up" />}
  >
    <FloatingActionBarWrapper>
      {/* Display selected items count */}
      <SelectedText>{`${selectedCodesLength} items selected`}</SelectedText>
      <ActionsWrapper direction="row" gap={2}>
        {/* Unselect all action */}
        <Typography
          onClick={handleUnselectAll}
          sx={{
            cursor: 'pointer',
            color: '#FFFFFF',
            fontSize: '14px',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Unselect all
        </Typography>
        {/* Archive button with loading state */}
        <ArchiveButton onClick={handleBulkArchive} disabled={isLoading}>
          <InboxIcon sx={{ marginRight: '8px' }} />
          {isLoading ? 'Archiving...' : 'Archive'}
        </ArchiveButton>
        {/* Show "Update usage limit" button conditionally */}
        {showUpdateButton && (
          <UpdateButton onClick={handleUpdateUsageLimit}>
            Update usage limit
          </UpdateButton>
        )}
      </ActionsWrapper>
    </FloatingActionBarWrapper>
  </Snackbar>
)

export default FloatingActionBarComponent
