import React, { useState, useContext, useEffect } from 'react'
import { Stack, Typography, TableCell } from '@mui/material'
import SearchInput from './components/SearchInput'
import ReusableTable from './components/ReusableTable'
import CampaignModal from './components/CampaignModal'
import StatusRow from './components/StatusRow'
import { ICampaign } from 'src/types/engage'
import {
  getCampaignsList,
  updateCampaign,
  updateBulkCampaigns,
} from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { toast } from 'react-toastify'
import CreateHookButton from './components/CreateHookButton'
import SortButton from './components/SortButton'
import FilterButton from './components/FilterButton'
import CampaignDetails from './components/CampaignDetails'
import LoadingComponent from 'src/components/LoadingComponent'
import dayjs from 'dayjs'

/**
 * ProjectDetail component handles the display and management of campaigns,
 * allowing search, filter, sort, and archive actions, and provides detailed
 * views for each campaign.
 */
const ProjectDetail: React.FC = () => {
  const {
    getWithAccessToken,
    putWithAccessToken,
    patchWithAccessToken,
    isLoading,
  } = useContext(Auth0Context)

  const [campaigns, setCampaigns] = useState<ICampaign[]>([])
  const [pagination, setPagination] = useState({ page: 1, limit: 10, count: 0 })
  const [filteredDataItems, setFilteredDataItems] = useState<ICampaign[]>([])
  const [sortField, setSortField] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [campaignData, setCampaignData] = useState<ICampaign | undefined>(
    undefined,
  )
  const [selectedCampaignId, setSelectedCampaignId] = useState<number | null>(
    null,
  )
  const [selectedCampaignName, setSelectedCampaignName] = useState<string>('')

  /**
   * Formats a date to ISO format for API compatibility.
   */
  const formatDateToISO = (date: string | Date) => {
    return dayjs(date).toISOString()
  }

  /**
   * Handles row click, setting the selected campaign details for further actions.
   */
  const handleRowClick = (campaign: ICampaign) => {
    setSelectedCampaignId(campaign.id)
    setSelectedCampaignName(campaign.name)
  }

  /**
   * Opens the modal for creating a new campaign.
   */
  const handleOpenCreateModal = () => {
    setIsEdit(false)
    setCampaignData(undefined)
    setOpenModal(true)
  }

  /**
   * Opens the modal for editing an existing campaign.
   */
  const handleOpenEditModal = (campaign: ICampaign) => {
    setIsEdit(true)
    setCampaignData(campaign)
    setOpenModal(true)
  }

  /**
   * Closes the modal and refreshes the campaign list if required.
   */
  const handleCloseModal = (refreshTable: boolean = false) => {
    setOpenModal(false)
    setCampaignData(undefined)
    if (refreshTable) {
      getCampaigns(pagination.page, pagination.limit, sortField, sortOrder)
    }
  }

  /**
   * Filters campaigns based on the search term provided by the user.
   */
  const handleSearch = (searchTerm: string) => {
    const filtered = campaigns.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    setFilteredDataItems(filtered)
    setPagination({ ...pagination, count: filtered.length, page: 1 })
  }

  /**
   * Sorts the campaigns list based on the selected field and order.
   */
  const handleSortChange = async (field: string, order: 'asc' | 'desc') => {
    setSortField(field)
    setSortOrder(order)
    setTableLoading(true)
    await getCampaigns(pagination.page, pagination.limit, field, order)
    setTableLoading(false)
  }

  /**
   * Refreshes the campaign table, fetching the latest data.
   */
  const refreshTable = async () => {
    setTableLoading(true)
    await getCampaigns(pagination.page, pagination.limit, sortField, sortOrder)
    setTableLoading(false)
  }

  /**
   * Archives a campaign by ID, with validation for required start and end dates.
   */
  const handleArchiveCampaignById = async (campaignId: number) => {
    try {
      const campaign = campaigns.find((item) => item.id === campaignId)
      if (!campaign) throw new Error('Campaign not found')

      if (!campaign.startDate || !campaign.endDate) {
        throw new Error(
          'Start Date and End Date are required to archive a campaign.',
        )
      }

      const formattedStartDate = formatDateToISO(campaign.startDate)
      const formattedEndDate = formatDateToISO(campaign.endDate)

      const payload = {
        id: campaign.id,
        name: campaign.name,
        start_date: formattedStartDate,
        end_date: formattedEndDate,
        status: 'archived',
      }

      await updateCampaign(putWithAccessToken, campaign.id, payload)

      toast.success(`Campaign "${campaign.name}" archived successfully!`)
      refreshTable()
    } catch (error: any) {
      toast.error(error.message || 'Failed to archive campaign')
    }
  }

  /**
   * Archives multiple campaigns in bulk.
   */
  const handleBulkArchiveCampaigns = async (selectedCampaignIds: number[]) => {
    try {
      const payload = { ids: selectedCampaignIds, status: 'archived' }
      await updateBulkCampaigns(patchWithAccessToken, payload)
      toast.success('Selected campaigns archived successfully!')
      refreshTable()
    } catch (error: any) {
      toast.error('Failed to archive selected campaigns')
    }
  }

  /**
   * Fetches the list of campaigns, applying pagination and sorting.
   */
  const getCampaigns = async (
    page: number = pagination.page,
    pageSize: number = pagination.limit,
    sortBy: string = sortField,
    direction: string = sortOrder,
  ) => {
    try {
      setTableLoading(true)
      const response = await getCampaignsList(
        getWithAccessToken,
        page,
        pageSize,
        sortBy,
        direction,
      )
      if (response?.results?.campaigns) {
        const apiCampaigns = response.results.campaigns.map(
          (campaign: any): ICampaign => ({
            id: campaign.id,
            name: campaign.name,
            startDate: campaign.start_date,
            endDate: campaign.end_date,
            status: campaign.status,
          }),
        )
        setCampaigns(apiCampaigns)
        setFilteredDataItems(apiCampaigns)
        setPagination((prev) => ({
          ...prev,
          count: response.results.count,
        }))
      }
    } catch (error) {
      toast.error('Failed to fetch campaigns data')
    } finally {
      setTableLoading(false)
    }
  }

  useEffect(() => {
    getCampaigns()
  }, [pagination.page, pagination.limit, sortField, sortOrder])

  /**
   * Handles pagination changes by updating the page number.
   */
  const handleChangePagination = ({ page }: { page: number }) => {
    setPagination((prev) => ({ ...prev, page }))
  }

  /**
   * Renders a table row for each campaign.
   */
  const renderRow = (campaign: ICampaign) => (
    <>
      <TableCell>
        <Typography
          style={{ cursor: 'pointer' }}
          onClick={() => handleRowClick(campaign)}
        >
          {campaign.name}
        </Typography>
      </TableCell>
      <TableCell>{campaign.startDate}</TableCell>
      <TableCell>{campaign.endDate}</TableCell>
      <TableCell>
        <StatusRow
          campaign={campaign}
          onChangeStatus={(newStatus) => {
            const updatedCampaigns = campaigns.map((item) =>
              item.id === campaign.id ? { ...item, status: newStatus } : item,
            )
            setCampaigns(updatedCampaigns)
            setFilteredDataItems(updatedCampaigns)
          }}
        />
      </TableCell>
    </>
  )

  return (
    <Stack sx={{ minHeight: '100%' }}>
      {isLoading ? (
        <LoadingComponent />
      ) : selectedCampaignId ? (
        <CampaignDetails
          campaignId={selectedCampaignId}
          campaignName={selectedCampaignName}
          onBack={() => setSelectedCampaignId(null)}
        />
      ) : (
        <Stack
          direction="column"
          gap={1}
          sx={{
            maxWidth: { xs: '100%', md: '90%' },
            width: '100%',
            height: '100%',
            position: 'relative',
            margin: '0px auto',
            padding: 2,
          }}
        >
          <Stack direction="column" sx={{ py: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Typography fontSize={'24px'} fontWeight={600}>
                Campaigns
              </Typography>
              <CreateHookButton onClick={handleOpenCreateModal} />
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <Stack direction="row" gap={2} alignItems="center">
                <SearchInput onSearch={handleSearch} />
                <FilterButton />
                <SortButton
                  onSortChange={handleSortChange}
                  sortField={sortField}
                  sortOrder={sortOrder}
                />
              </Stack>
            </Stack>
          </Stack>

          <ReusableTable
            hooks={filteredDataItems ?? []}
            pagination={pagination}
            headers={['Name', 'Start Date', 'End Date', 'Status']}
            renderRow={renderRow}
            onChangePagination={handleChangePagination}
            goToEditHook={handleOpenEditModal}
            onArchiveHook={handleArchiveCampaignById}
            onBulkArchive={handleBulkArchiveCampaigns}
            loading={tableLoading}
            refreshTable={refreshTable}
          />

          <CampaignModal
            open={openModal}
            onClose={handleCloseModal}
            isEdit={isEdit}
            campaignData={campaignData}
          />
        </Stack>
      )}
    </Stack>
  )
}

export default ProjectDetail
