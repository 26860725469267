import React, { useContext } from 'react'
import {
  Stack,
  MenuItem,
  Select,
  Typography,
  SelectChangeEvent,
} from '@mui/material'
import { styled } from '@mui/system'
import { newColor } from 'src/consts/colors'
import { updateCampaign } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

// Status indicator dot styling
const StatusDot = styled('div')<{ color: string }>(({ color }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: '6px',
}))

// Mapping of statuses to corresponding colors and labels
const statusColors: { [key: string]: string } = {
  not_started: '#1A74A8',
  active: '#067A57',
  finished: '#9654F0',
  archived: '#545454',
}

const statusLabels: { [key: string]: string } = {
  not_started: 'Not Started',
  active: 'Active',
  finished: 'Finished',
  archived: 'Archived',
}

interface StatusRowProps {
  campaign: {
    id: number
    name: string
    startDate: string
    endDate: string
    status: string
  }
  onChangeStatus: (newStatus: string) => void
}

const StatusRow: React.FC<StatusRowProps> = ({ campaign, onChangeStatus }) => {
  const { putWithAccessToken } = useContext(Auth0Context)

  // Format date to ISO 8601
  const formatDateTime = (date: string) => dayjs(date).toISOString()

  const handleStatusChange = async (event: SelectChangeEvent<string>) => {
    const newStatus = event.target.value
    onChangeStatus(newStatus)

    try {
      const payload = {
        name: campaign.name,
        status: newStatus,
        start_date: formatDateTime(campaign.startDate),
        end_date: formatDateTime(campaign.endDate),
      }

      await updateCampaign(putWithAccessToken, campaign.id, payload)
      toast.success('Campaign updated successfully!')
    } catch (error) {
      console.error('Failed to update campaign status', error)
    }
  }

  return (
    <Select
      value={campaign.status}
      onChange={handleStatusChange}
      displayEmpty
      sx={{
        backgroundColor: newColor.charcoal,
        color: newColor.white,
        borderRadius: '6px',
        padding: '4px 10px',
        display: 'flex',
        alignItems: 'center',
        width: '164px',
        fontSize: '0.875rem',
        '.MuiSelect-select': {
          padding: '6px 8px',
        },
      }}
    >
      {Object.keys(statusColors).map((statusKey) => (
        <MenuItem
          value={statusKey}
          key={statusKey}
          sx={{ padding: '8px 16px' }}
        >
          <Stack direction="row" alignItems="center">
            <StatusDot color={statusColors[statusKey]} />
            <Typography sx={{ color: newColor.white, fontSize: '14px' }}>
              {statusLabels[statusKey]}
            </Typography>
          </Stack>
        </MenuItem>
      ))}
    </Select>
  )
}

export default StatusRow
