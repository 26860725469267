import React from "react";
import { toast } from "react-toastify";
import * as ReactTooltip from 'react-tooltip';
import { AiFillCloseCircle } from "react-icons/ai";
import { Box } from "@mui/material";
import { Colors } from "helika-ui-sdk";


export const MAX_FILE_SIZE = 5000000
export const formatImageUrl = (id: string | null | undefined) => {
    if (!id) {
        return ""; // invalid url
    }
    return `https://${process.env.REACT_APP_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/` + id;
}

export default function UploadImageComponent({
    image,//the serch term to be used to filter assets,etc
    setImage,//function to change the search term
    storedImageId,
    setStoredImageId,
    disabled = false,
}: {
    image: any,
    storedImageId?: string,
    setImage: React.Dispatch<React.SetStateAction<any>>,
    setStoredImageId?: React.Dispatch<React.SetStateAction<any>>,
    disabled?: boolean,
}) {

    const hiddenFileInputDisplay = React.useRef<HTMLInputElement>(null);
    // const [storedImage, setStoredImage] = useState<any>();

    const handleClickDisplay = () => {
        if (disabled) return
        if (hiddenFileInputDisplay.current !== null) {
            hiddenFileInputDisplay.current.click();
        }
    };

    return (
        <div>
            <ReactTooltip.Tooltip />
            <span className="hidden">
                <input
                    ref={hiddenFileInputDisplay}
                    style={{ display: 'none' }}
                    type="file"
                    onChange={(e) => {
                        if (e.target.files && e.target.files[0]) {
                            if (e.target.files[0].size > MAX_FILE_SIZE) {
                                toast.error('Image must be no greater than 5mb.');
                                return;
                            }
                            setImage(e.target.files[0]);
                            if (setStoredImageId) setStoredImageId(undefined);
                        }
                    }}
                    accept='.png,.jpg,.jpeg'
                />
            </span>
            {
                (image || storedImageId)
                    ?
                    <Box
                        sx={{
                            height: '8em',
                            width: '8em',
                            background: Colors.background.tertiary,
                            borderRadius: '5px'
                        }}
                    >
                        {
                            (image || storedImageId) && !disabled &&
                            <div
                                style={{
                                    display: 'flex',
                                    zIndex: 50,
                                    position: 'relative',
                                }}
                                className="flex flex-row justify-end translate-y-full"
                            >
                                <AiFillCloseCircle
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        setImage(undefined)
                                        if (setStoredImageId) setStoredImageId(undefined)
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        marginLeft: 'auto',
                                        color: 'red'
                                    }}
                                    size={'2em'}
                                />
                            </div>
                        }
                        <div
                            onClick={() => handleClickDisplay()}
                            style={{
                                height: '8em',
                                width: '8em',
                                background: Colors.background.tertiary,
                                borderRadius: '5px',
                                marginTop: '-2em',
                                justifyContent: 'center',
                                zIndex: 10,
                                position: 'relative'
                            }}
                        >
                            <img
                                src={(image && image.size !== 0) ? URL.createObjectURL(image) : `${formatImageUrl(storedImageId)}`}
                                style={{
                                    borderRadius: '5px',
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'cover',//contain
                                }}
                                alt='uploaded_image'
                            />
                        </div>
                    </Box>
                    :
                    <Box
                        onClick={handleClickDisplay}
                        style={{
                            height: '8em',
                            width: '8em',
                            background: Colors.background.tertiary,
                            borderRadius: '5px',
                            cursor: 'pointer'
                        }}
                    />
            }
        </div>
    );
}