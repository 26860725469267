import React, { useState } from 'react'
import { IconButton, Menu, MenuItem, Typography } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import ArchiveIcon from '@mui/icons-material/Archive'
import { ICampaign } from 'src/types/engage'
import { styled } from '@mui/system'

// Styled menu item with consistent appearance
const MenuItemWrapper = styled(MenuItem)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 16px',
  gap: '8px',
  width: '200px',
  height: '36px',
  background: '#1C1C1C',
  color: '#FFFFFF',
  '&:hover': {
    background: '#333333',
  },
}))

// Typography for menu item text
const MenuTypography = styled(Typography)(() => ({
  fontFamily: 'Open Sans',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '140%',
  color: '#FFFFFF',
}))

// Styled menu container for consistent appearance
const MenuContainer = styled(Menu)(() => ({
  '.MuiPaper-root': {
    background: '#1C1C1C',
    boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
  },
}))

interface HookActionsMenuProps {
  hook: ICampaign
  onEdit: (hook: ICampaign) => void
  onArchive: (hook: ICampaign) => Promise<void>
  refreshTable: () => void
}

const HookActionsMenu: React.FC<HookActionsMenuProps> = ({
  hook,
  onEdit,
  onArchive,
  refreshTable,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // Trigger the edit action
  const handleEdit = () => {
    onEdit(hook)
    handleClose()
  }

  // Trigger the archive action and refresh the table after archiving
  const handleArchive = async () => {
    try {
      setIsLoading(true)
      await onArchive(hook)
      refreshTable()
      handleClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <IconButton
        aria-controls="hook-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
        disabled={isLoading}
      >
        <MoreVertIcon />
      </IconButton>
      <MenuContainer
        id="hook-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {/* Edit Menu Item */}
        <MenuItemWrapper onClick={handleEdit} disabled={isLoading}>
          <EditIcon sx={{ color: '#D2D2D3' }} />
          <MenuTypography>Edit</MenuTypography>
        </MenuItemWrapper>
        {/* Archive Menu Item */}
        <MenuItemWrapper onClick={handleArchive} disabled={isLoading}>
          <ArchiveIcon sx={{ color: '#D2D2D3' }} />
          <MenuTypography>Archive</MenuTypography>
        </MenuItemWrapper>
      </MenuContainer>
    </>
  )
}

export default HookActionsMenu
