import React from 'react'
import { Stack, Typography, Button } from '@mui/material'
import { styled } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import { newColor } from 'src/consts/colors'
import InboxEmptyIcon from 'src/assets/Inbox_empty.svg' // Ensure the correct import path

// Styled Button for the "Create New Code"
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: newColor.jazzberryJam,
  color: 'white',
  fontWeight: 'bold',
  borderRadius: '5px',
  padding: '5px 15px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: '#E91E63',
  },
}))

const NoDataAvailable: React.FC<{ onCreateNew: () => void }> = ({
  onCreateNew,
}) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        height: '100%',
        textAlign: 'center',
        backgroundColor: 'transparent',
        padding: 4,
      }}
    >
      <img
        src={InboxEmptyIcon}
        alt="No Data Illustration"
        style={{ width: '100px', height: '100px', marginBottom: '16px' }}
      />
      <Typography
        variant="h6"
        color="white"
        sx={{ fontWeight: 'bold', marginBottom: '8px' }}
      >
        All redemption codes in one place
      </Typography>
      <Typography variant="body2" color="white" sx={{ marginBottom: '24px' }}>
        Start creating a code for this campaign.
      </Typography>
      <StyledButton startIcon={<AddIcon />} onClick={onCreateNew}>
        Create New Code
      </StyledButton>
    </Stack>
  )
}

export default NoDataAvailable
