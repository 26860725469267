import React, { useState } from 'react'
import {
  Button,
  Typography,
  Popover,
  Stack,
  MenuItem,
  Select,
  IconButton,
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/system'

// Styled components for filter button and popover elements
const FilterButtonWrapper = styled(Button)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px',
  width: 'auto',
  minWidth: '91px',
  height: '36px',
  boxSizing: 'border-box',
  border: '1px solid #434D5A',
  borderRadius: '4px',
  background: '#000000',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#333333',
  },
})

const FilterIcon = styled(FilterListIcon)({
  width: '24px',
  height: '24px',
  color: '#D2D2D3',
  paddingLeft: '8px', // Padding for better alignment
})

const PopoverContainer = styled(Popover)({
  '.MuiPopover-paper': {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 0px',
    width: '452px',
    background: '#1C1C1C',
    boxShadow: '0px 20px 20px rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
    border: 'none',
    overflow: 'hidden',
  },
})

// Container for filter fields and rows
const FilterContainer = styled(Stack)({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px 16px',
  gap: '12px',
  width: '100%',
  background: '#1C1C1C',
})

// Filter rows layout
const FilterRow = styled(Stack)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  width: '100%',
  justifyContent: 'space-between',
})

// Styled select fields for filtering options
const SelectField = styled(Select)({
  backgroundColor: '#1C2025',
  border: '1px solid #434D5A',
  borderRadius: '4px',
  color: '#FFFFFF',
  height: '36px',
  padding: '8px 12px',
  width: '141px',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '140%',
})

// Styled "Clear All" button at the bottom of the filter popover
const ClearAllButton = styled(Typography)({
  fontSize: '14px',
  color: '#FFFFFF',
  cursor: 'pointer',
  textAlign: 'center',
  marginTop: '8px',
})

const FilterButton = () => {
  // State to handle the open/close of the filter popover
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  // Handle click to open the filter popover
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  // Handle closing of the filter popover
  const handleClose = () => {
    setAnchorEl(null)
  }

  // Define if the popover is open based on the anchor state
  const open = Boolean(anchorEl)
  const id = open ? 'filter-popover' : undefined

  return (
    <>
      {/* Filter Button with icon and label */}
      <FilterButtonWrapper onClick={handleClick}>
        <FilterIcon />
        <Typography
          sx={{
            fontSize: '14px',
            color: '#FFFFFF',
            paddingLeft: '8px',
            paddingRight: '8px',
            whiteSpace: 'nowrap',
          }}
        >
          FILTER
        </Typography>
      </FilterButtonWrapper>

      {/* Popover for filter options */}
      <PopoverContainer
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <FilterContainer>
          {/* Filter by label */}
          <FilterRow>
            <Typography
              sx={{
                fontSize: '14px',
                color: '#FFFFFF',
              }}
            >
              Filter by
            </Typography>
          </FilterRow>

          {/* Dropdowns for filter options */}
          <FilterRow>
            <SelectField defaultValue="Category">
              <MenuItem value="Category">Category</MenuItem>
              <MenuItem value="Date">Date</MenuItem>
              <MenuItem value="Status">Status</MenuItem>
            </SelectField>

            <SelectField defaultValue="is">
              <MenuItem value="is">is</MenuItem>
              <MenuItem value="is not">is not</MenuItem>
            </SelectField>

            <SelectField defaultValue="Value1">
              <MenuItem value="Value1">Value 1</MenuItem>
              <MenuItem value="Value2">Value 2</MenuItem>
            </SelectField>

            {/* Close button inside the popover */}
            <IconButton onClick={handleClose}>
              <CloseIcon sx={{ color: '#FFFFFF' }} />
            </IconButton>
          </FilterRow>

          {/* "Clear All" button to reset filters */}
          <ClearAllButton onClick={() => console.log('Clear all filters')}>
            CLEAR ALL
          </ClearAllButton>
        </FilterContainer>
      </PopoverContainer>
    </>
  )
}

export default FilterButton
