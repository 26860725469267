import React, { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Checkbox,
  Pagination,
  Box,
} from '@mui/material'
import HookActionsMenu from './HookActionsMenu'
import LoadingComponent from 'src/components/LoadingComponent'
import FloatingActionBarComponent from './FloatingActionBarComponent'
import { toast } from 'react-toastify'

interface ReusableTableProps<T> {
  hooks: T[]
  pagination: {
    page: number
    limit: number
    count: number
  }
  headers: string[]
  renderRow: (hook: T) => React.ReactNode
  onChangePagination: ({ page, limit }: { page: number; limit: number }) => void
  goToEditHook: (hook: T) => void
  loading: boolean
  refreshTable: () => void
  onArchiveHook?: (hookId: number) => Promise<void>
  onBulkArchive?: (selectedIds: number[]) => Promise<void>
  showUpdateButton?: boolean
  handleUpdateUsageLimit?: (selectedIds: number[]) => void
}

const ReusableTable: React.FC<ReusableTableProps<any>> = ({
  hooks,
  pagination,
  headers,
  renderRow,
  onChangePagination,
  goToEditHook,
  loading,
  refreshTable,
  onArchiveHook,
  onBulkArchive,
  showUpdateButton,
  handleUpdateUsageLimit,
}) => {
  const [selectedHooks, setSelectedHooks] = useState<number[]>([])
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isBulkUpdating, setIsBulkUpdating] = useState(false)

  // Handle the selection of all hooks
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = hooks.map((hook: any) => hook.id)
      setSelectedHooks(newSelecteds)
      setSnackbarOpen(true)
      return
    }
    setSelectedHooks([])
    setSnackbarOpen(false)
  }

  // Handle individual hook selection
  const handleSelectClick = (hookId: number) => {
    const selectedIndex = selectedHooks.indexOf(hookId)
    let newSelected: number[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedHooks, hookId)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedHooks.slice(1))
    } else if (selectedIndex === selectedHooks.length - 1) {
      newSelected = newSelected.concat(selectedHooks.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedHooks.slice(0, selectedIndex),
        selectedHooks.slice(selectedIndex + 1),
      )
    }

    setSelectedHooks(newSelected)
    setSnackbarOpen(newSelected.length > 0)
  }

  const isSelected = (hookId: number) => selectedHooks.indexOf(hookId) !== -1

  const totalPages = Math.ceil(pagination.count / pagination.limit)

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    onChangePagination({ page, limit: pagination.limit })
  }

  // Handle bulk archiving
  const handleBulkArchive = async () => {
    if (!selectedHooks.length) {
      toast.error('No items selected for archiving.')
      return
    }

    setIsBulkUpdating(true)

    try {
      if (onBulkArchive) {
        await onBulkArchive(selectedHooks)
      }

      handleUnselectAll()
      refreshTable()
    } catch (e: any) {
      console.error(e?.message || 'Failed to archive selected items.')
    } finally {
      setIsBulkUpdating(false)
    }
  }

  const handleUnselectAll = () => {
    setSelectedHooks([])
    setSnackbarOpen(false)
  }

  // Handle usage limit update
  const handleUpdateUsageLimitClick = () => {
    if (!selectedHooks.length) {
      toast.error('No items selected for updating usage limit.')
      return
    }
    if (handleUpdateUsageLimit) {
      handleUpdateUsageLimit(selectedHooks)
    }
  }

  return (
    <Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={
                    selectedHooks.length > 0 &&
                    selectedHooks.length < hooks.length
                  }
                  checked={
                    hooks.length > 0 && selectedHooks.length === hooks.length
                  }
                  onChange={handleSelectAllClick}
                />
              </TableCell>
              {headers.map((header: string, index: number) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={headers.length + 2} align="center">
                  <LoadingComponent />
                </TableCell>
              </TableRow>
            ) : hooks.length > 0 ? (
              hooks.map((hook: any) => {
                const isItemSelected = isSelected(hook.id)
                return (
                  <TableRow key={hook.id} selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onChange={() => handleSelectClick(hook.id)}
                      />
                    </TableCell>
                    {renderRow(hook)}
                    <TableCell align="right">
                      <HookActionsMenu
                        hook={hook}
                        onEdit={goToEditHook}
                        onArchive={
                          onArchiveHook
                            ? async () => {
                                await onArchiveHook(hook.id)
                              }
                            : async () => Promise.resolve()
                        }
                        refreshTable={refreshTable}
                      />
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={headers.length + 2} align="center">
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 3 }}>
        <Pagination
          count={totalPages}
          page={pagination.page}
          onChange={handlePageChange}
        />
      </Box>

      {selectedHooks.length > 0 && (
        <FloatingActionBarComponent
          selectedCodesLength={selectedHooks.length}
          handleBulkArchive={handleBulkArchive}
          handleUnselectAll={handleUnselectAll}
          open={isSnackbarOpen}
          isLoading={isBulkUpdating}
          showUpdateButton={showUpdateButton}
          handleUpdateUsageLimit={handleUpdateUsageLimitClick}
        />
      )}
    </Stack>
  )
}

export default ReusableTable
