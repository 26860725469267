//imports
import React from 'react';

//contexts

//services

//assets
import { useTheme } from '@emotion/react';
import { ReactComponent as Search } from '../assets/search.svg';
import { StyledSvg } from './organisms/v2/tabs/styled';
import { newColor } from 'src/consts/colors';
import { Colors } from 'helika-ui-sdk';
//----------------------------------------------------------------------------------------------------------

export default function HelikaSearchBar({
    value,
    setValue,
    placeholder,
    onEnter,
}: {
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    placeholder?: string,
    onEnter?: any,
}) {

    const theme: any = useTheme();

    return (
        <div
            style={{
                height: '2.4em',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '100%',
            }}
        >
            <div
                style={{
                    justifyContent: 'left',
                    display: 'flex',
                    flexDirection: 'row',
                    borderStyle: 'solid',
                    borderRadius: '3px',
                    padding: '0 0 0 1em',
                    width: '100%',
                    background: Colors.background.tertiary,
                    borderColor: Colors.blackAndWhite.grey400,
                    borderWidth: '1px',
                    height: '100%',
                }}
            >
                <div
                    style={{
                        margin: 'auto 0.5em auto 0em',
                        height: '1em',
                    }}
                >
                    <div
                        style={{
                            margin: '-0.2em 0 2em 0',
                        }}
                    >
                        <StyledSvg>
                            <Search />
                        </StyledSvg>
                    </div>
                </div>
                <input
                    style={{
                        background: newColor.transparent,
                        color: theme.palette.text.primary,
                        outline: 'none',
                        boxShadow: 'none',
                        border: 'none',
                        borderRadius: '100vmax',
                        textOverflow: 'ellipsis',
                        fontSize: '0.8em',
                        width: '100%',
                    }}
                    type='text'
                    placeholder={placeholder ? placeholder : ' SEARCH'}
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        const newVal = e.currentTarget.value.trim();
                        if (e.key === 'Enter') {
                            if (onEnter && typeof onEnter === 'function') {
                                onEnter(newVal)
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
}