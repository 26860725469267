import React from 'react'
import { styled as muiStyled } from '@mui/material'
import { newColor } from '../../consts/colors'

export const StyledSvg = muiStyled('span')(({ theme }) => ({
  fontSize: 0,

  '& path[fill]': {
    fill: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  },

  '& path[stroke]': {
    stroke: theme.palette.mode === 'dark' ? newColor.white : newColor.black,
  },
}))

export function HelikaDataGridDefaultHeaderComponent(props: any) {

  return (
    <div id='hell' style={{
      margin: 'auto', width: 'fit-content', height: '100%',
      minWidth: '100%'
    }}>
      <div
        className="supportHeaderParent"
        style={{
          color: props.theme?.palette.text.primary,
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'start',
          margin: 'auto',
          width: 'fit-content',
          height: '100%',
          minWidth: '100%'
        }}
      >
        <div className="customHeaderLabel" style={{
          margin: 'auto', height: '100%',
          minHeight: '2em',
          minWidth: '100%'
        }}>
          {props.colDef?.headerName}
        </div>
      </div>
    </div>
  )
}
