import React, { useState, useContext, useEffect } from 'react'
import {
  Stack,
  Typography,
  Breadcrumbs,
  Link,
  TableCell,
  Button,
  Snackbar,
} from '@mui/material'
import { styled } from '@mui/system'
import { newColor } from 'src/consts/colors'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import CustomSwitch from './CustomSwitch'
import {
  getCampaignDetails,
  postNewCode,
  updateCode,
  updateBulkCodes,
} from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { toast } from 'react-toastify'
import NoDataAvailable from './NoDataAvailable'
import FilterButton from './FilterButton'
import SortButton from './SortButton'
import ReusableTable from './ReusableTable'
import CreateRedemptionCodePage from './CreateRedemptionCodePage'
import EditUsageLimitModal from './EditUsageLimitModal'

// Styled button for creating a new redemption code
const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: newColor.jazzberryJam,
  color: 'white',
  borderRadius: '5px',
  padding: '5px 15px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: '#E91E63',
  },
  svg: {
    marginRight: '8px',
  },
}))

interface RedemptionCode {
  id: number
  code: string
  codeUrl: string
  type: string
  usageLimit: number
  status: string
  name: string
  base_url: string
  rewards?: any[]
}

const CampaignDetails: React.FC<{
  campaignId: number
  campaignName: string
  onBack: () => void
}> = ({ campaignId, campaignName, onBack }) => {
  const {
    getWithAccessToken,
    postWithAccessToken,
    putWithAccessToken,
    patchWithAccessToken,
  } = useContext(Auth0Context)

  // State management for redemption codes, pagination, loading, and other controls
  const [redemptionCodes, setRedemptionCodes] = useState<RedemptionCode[]>([])
  const [pagination, setPagination] = useState({ page: 1, limit: 10, count: 0 })
  const [sortField, setSortField] = useState<string>('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const [loading, setLoading] = useState<boolean>(true)
  const [isCreating, setIsCreating] = useState(false)
  const [isSnackbarOpen, setSnackbarOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedUsageLimit, setSelectedUsageLimit] = useState<number>(1)
  const [editingCode, setEditingCode] = useState<RedemptionCode | null>(null)
  const [selectedCodesForUpdate, setSelectedCodesForUpdate] = useState<
    number[]
  >([])

  // Fetch redemption codes from the API
  const fetchRedemptionCodes = async () => {
    try {
      setLoading(true)
      const data = await getCampaignDetails(
        getWithAccessToken,
        pagination.page,
        pagination.limit,
        sortField,
        sortOrder,
        campaignId,
      )
      if (data && data.results && data.results.codes) {
        const receivedCodes = data.results.codes.map((code: any) => ({
          id: code.id,
          code: code.code,
          codeUrl: `${code.base_url}?code=${code.code}`,
          type: code.type,
          usageLimit: code.number_of_usage,
          status: code.status,
          name: code.name,
          base_url: code.base_url,
          rewards: code.rewards || [],
        }))
        setRedemptionCodes(receivedCodes)
        setPagination((prev) => ({ ...prev, count: data.results.count || 0 }))
      }
    } catch (e: any) {
      toast.error(e?.message || 'Error fetching campaign details')
    } finally {
      setLoading(false)
    }
  }

  // Open modal for bulk updating usage limits
  const handleOpenEditModalForBulk = (selectedIds: number[]) => {
    if (selectedIds.length === 0) {
      toast.error('No codes selected for updating usage limit.')
      return
    }
    setSelectedCodesForUpdate(selectedIds)
    setIsEditModalOpen(true)
  }

  // Save updated usage limit for selected codes
  const handleSaveUsageLimit = async () => {
    try {
      const payload = {
        ids: selectedCodesForUpdate,
        number_of_usage: selectedUsageLimit,
      }
      await updateBulkCodes(patchWithAccessToken, payload)
      toast.success('Usage limit updated successfully!')
      setIsEditModalOpen(false)
      await fetchRedemptionCodes()
    } catch (error) {
      toast.error('Failed to update usage limit')
    }
  }

  // Change status (active/archived) for a specific code
  const handleStatusChange = async (codeId: number, newStatus: boolean) => {
    const code = redemptionCodes.find((c) => c.id === codeId)
    if (!code) return

    const updatedStatus = newStatus ? 'active' : 'archived'
    const originalStatus = code.status

    const updatedRedemptionCodes = redemptionCodes.map((c) =>
      c.id === codeId ? { ...c, status: updatedStatus } : c,
    )
    setRedemptionCodes(updatedRedemptionCodes)

    try {
      const updatedCodeData = {
        campaign_id: campaignId,
        code: code.code,
        base_url: code.base_url,
        name: code.name,
        type: code.type,
        number_of_usage: code.usageLimit,
        status: updatedStatus,
        rewards: code.rewards ?? [],
      }
      await updateCode(putWithAccessToken, code.id, updatedCodeData)
      toast.success(`Code status updated to ${updatedStatus}`)
    } catch (error) {
      toast.error('Failed to update code status, reverting the change')
      const revertedRedemptionCodes = redemptionCodes.map((c) =>
        c.id === code.id ? { ...c, status: originalStatus } : c,
      )
      setRedemptionCodes(revertedRedemptionCodes)
    }
  }

  // Archive a specific code
  const handleArchive = async (codeId: number) => {
    await handleStatusChange(codeId, false)
  }

  // Bulk archive selected codes
  const handleBulkArchive = async (selectedCodeIds: number[]) => {
    try {
      const payload = {
        ids: selectedCodeIds,
        status: 'archived',
      }
      await updateBulkCodes(patchWithAccessToken, payload)
      toast.success('Selected codes archived successfully!')
      await fetchRedemptionCodes()
    } catch (error: any) {
      toast.error('Failed to archive selected codes')
    }
  }

  // Save or create new redemption code
  const handleSaveNewCode = async (newCodeData: any) => {
    try {
      if (editingCode) {
        await updateCode(putWithAccessToken, editingCode.id, newCodeData)
        toast.success('Redemption code updated successfully!')
      } else {
        await postNewCode(postWithAccessToken, newCodeData)
        toast.success('Redemption code created successfully!')
      }
      setIsCreating(false)
      setEditingCode(null)
      await fetchRedemptionCodes()
    } catch (e: any) {
      toast.error(e?.message || 'Failed to save redemption code')
    }
  }

  // Fetch redemption codes when pagination, sorting, or campaign ID changes
  useEffect(() => {
    fetchRedemptionCodes()
  }, [pagination.page, pagination.limit, sortField, sortOrder, campaignId])

  return (
    <Stack sx={{ minHeight: '100%' }}>
      {!isCreating && !editingCode ? (
        <Stack
          direction="column"
          gap={1}
          sx={{
            maxWidth: { xs: '100%', md: '90%' },
            width: '100%',
            height: '100%',
            position: 'relative',
            margin: '0px auto',
            padding: 2,
          }}
        >
          <Stack direction="column" sx={{ py: 2 }}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <ArrowForwardIosIcon
                  sx={{ fontSize: 'small', color: '#FFFFFF' }}
                />
              }
            >
              <Typography color="text.primary" sx={{ color: '#FFFFFF' }}>
                Redemption Codes
              </Typography>
              <Link
                component="button"
                onClick={onBack}
                sx={{ textDecoration: 'none', color: '#FFFFFF' }}
              >
                Campaign
              </Link>
              <Typography color="text.primary" sx={{ color: '#FFFFFF' }}>
                {campaignName}
              </Typography>
            </Breadcrumbs>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Typography fontSize={35} fontWeight={600}>
                {campaignName} - Redemption Codes
              </Typography>
              <StyledButton onClick={() => setIsCreating(true)}>
                <AddIcon sx={{ marginRight: '10px' }} /> Create New Code
              </StyledButton>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <Stack direction="row" gap={2}>
                <FilterButton />
                <SortButton
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSortChange={(field, order) => {
                    setSortField(field)
                    setSortOrder(order)
                  }}
                />
              </Stack>
            </Stack>

            {redemptionCodes.length === 0 ? (
              <NoDataAvailable onCreateNew={() => setIsCreating(true)} />
            ) : (
              <ReusableTable
                hooks={redemptionCodes}
                headers={['Code', 'Code URL', 'Type', 'Usage Limit', 'Status']}
                pagination={pagination}
                renderRow={(code: RedemptionCode) => (
                  <>
                    <TableCell>{code.code}</TableCell>
                    <TableCell>{code.codeUrl}</TableCell>
                    <TableCell>{code.type}</TableCell>
                    <TableCell>{code.usageLimit}</TableCell>
                    <TableCell>
                      <CustomSwitch
                        checked={code.status === 'active'}
                        onChange={() =>
                          handleStatusChange(code.id, code.status !== 'active')
                        }
                        icon={<RemoveIcon />}
                      />
                    </TableCell>
                  </>
                )}
                onChangePagination={({ page, limit }) =>
                  setPagination((prev) => ({ ...prev, page, limit }))
                }
                loading={loading}
                refreshTable={fetchRedemptionCodes}
                goToEditHook={(code: RedemptionCode) => setEditingCode(code)}
                onArchiveHook={handleArchive}
                onBulkArchive={handleBulkArchive}
                showUpdateButton={true}
                handleUpdateUsageLimit={handleOpenEditModalForBulk}
              />
            )}
          </Stack>

          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={2000}
            onClose={() => setSnackbarOpen(false)}
            message="Copied to clipboard!"
          />
        </Stack>
      ) : (
        <CreateRedemptionCodePage
          onSave={handleSaveNewCode}
          onBack={() => {
            setIsCreating(false)
            setEditingCode(null)
          }}
          campaignName={campaignName}
          campaignId={campaignId}
          existingCodeData={editingCode}
        />
      )}

      <EditUsageLimitModal
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        usageLimit={selectedUsageLimit}
        setUsageLimit={setSelectedUsageLimit}
        handleSave={handleSaveUsageLimit}
      />
    </Stack>
  )
}

export default CampaignDetails
