import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { get, post, patch, put, deleteReq, build_url_with_params } from '../utils/api/frontend';
import moment from 'moment';
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { actionCreators } from "../state";
import { handleError } from '../utils/api/errors';
import _ from 'lodash'

export const Auth0Context = React.createContext();

export const AuthContextProvider = ({ children }) => {

  const dispatch = useDispatch();
  const { setUserData, setNotifications, setAnalyticsState, setGameManagementState, setAccountManagementState, setSupportState } = bindActionCreators(actionCreators, dispatch);
  const { user, isAuthenticated, getAccessTokenSilently, error, isLoading, loginWithRedirect, logout } = useAuth0();

  const [accessTokenCache, setAccessTokenCache] = useState();

  async function clearAllData() {
    setUserData({
      type: 'CLEAR_DATA'
    });
    setNotifications({
      type: 'CLEAR_DATA'
    });
    setAnalyticsState({
      type: 'CLEAR_DATA'
    });
    setGameManagementState({
      type: 'CLEAR_DATA'
    });
    setAccountManagementState({
      type: 'CLEAR_DATA'
    });
    setSupportState({
      type: 'CLEAR_DATA'
    });
    sessionStorage.clear()
    localStorage.clear()
  }

  async function refreshLogin(msg) {
    toast(msg);
    clearAllData();
    setTimeout(async () => {
      await loginWithRedirect();
    }, 3000);
  }

  const getTokenIfNecessary = useCallback(async () => {
    let accessToken = accessTokenCache?.accessToken;
    try {
      if (!accessToken || !accessTokenCache?.expiry || !(accessTokenCache?.expiry.isAfter(new Date()))) {
        accessToken = await getAccessTokenSilently({
          //authorizationParams: {
          //  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          //  scope: "openid email profile"
          //},
        });
        let cachedToken = {
          accessToken: accessToken,
          expiry: moment(new Date()).add(20, 'hours')
        }
        setAccessTokenCache(cachedToken);
      }
    } catch (e) {
      if (e.message === 'Login required') {
        refreshLogin('Login required, you will be redirected');
      }
      throw new Error(e);
    }

    return accessToken;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessTokenCache, getAccessTokenSilently, loginWithRedirect])

  const getWithAccessToken = useCallback(async (url, params) => {
    try {
      if (!isAuthenticated) {
        throw new Error('Login required')
      }
      let accessToken = await getTokenIfNecessary();

      let newUrl = build_url_with_params(url, params);
      let options = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      }
      if (!_.isEmpty(params?.helika_api_key)) {
        options.headers["x-api-key"] = params.helika_api_key
      }
      return await get(newUrl, options);

    } catch (e) {
      if (e.message === 'Login required') {
        refreshLogin('Login required, you will be redirected');
      } else {
        handleError(e);
      }
      throw new Error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTokenIfNecessary, isAuthenticated, loginWithRedirect])


  const postWithAccessToken = useCallback(async (url, params, errorHandler = undefined, signal = undefined) => {
    try {
      if (!isAuthenticated) {
        throw new Error('Login required')
      }
      let accessToken = await getTokenIfNecessary();
      let options = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      }
      let params_to_be_passed = Object.assign({}, params)
      if (!_.isEmpty(params?.helika_api_key)) {
        delete params_to_be_passed.helika_api_key
        options.headers["x-api-key"] = params.helika_api_key
      }
      if (params_to_be_passed) {
        options.body = JSON.stringify(params_to_be_passed)
      }
      return await post(url, options, errorHandler, signal);

    } catch (e) {
      if (e.message === 'Login required') {
        refreshLogin('Login required, you will be redirected');
      } else {
        throw new Error(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTokenIfNecessary, isAuthenticated, loginWithRedirect])

  const deleteWithAccessToken = useCallback(async (url, params) => {
    try {
      if (!isAuthenticated) {
        throw new Error('Login required')
      }
      let accessToken = await getTokenIfNecessary();
      let options = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      }
      if (params) {
        options.body = JSON.stringify(params)
      }
      return await deleteReq(url, options);

    } catch (e) {
      if (e.message === 'Login required') {
        refreshLogin('Login required, you will be redirected');
      } else {
        handleError(e);
      }
      throw new Error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTokenIfNecessary, isAuthenticated, loginWithRedirect])


  const patchWithAccessToken = useCallback(async (url, params) => {
    try {
      if (!isAuthenticated) {
        throw new Error('Login required')
      }
      let accessToken = await getTokenIfNecessary();
      let options = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      }
      if (params) {
        options.body = JSON.stringify(params)
      }
      return await patch(url, options)

    } catch (e) {
      if (e.message === 'Login required') {
        refreshLogin('Login required, you will be redirected');
      } else {
        handleError(e);
      }
      throw new Error(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTokenIfNecessary, isAuthenticated, loginWithRedirect])

  const putWithAccessToken = useCallback(
    async (url, params) => {
      try {
        if (!isAuthenticated) {
          throw new Error('Login required')
        }

        // Get access token
        let accessToken = await getTokenIfNecessary()

        // Prepare request options
        let options = {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json', // Important for JSON requests
          },
        }

        // Add request body if params are provided
        if (params) {
          options.body = JSON.stringify(params)
        }

        // Make the PUT request
        return await put(url, options)
      } catch (e) {
        if (e.message === 'Login required') {
          refreshLogin('Login required, you will be redirected')
        } else {
          handleError(e) // Use handleError for proper error management
        }
        throw new Error(e) // Re-throw error after handling
      }
    },
    [getTokenIfNecessary, isAuthenticated, loginWithRedirect],
  )
  

  //if not authenticated, redirect to login
  useEffect(() => {
    async function checkLoggedIn() {
      clearAllData();
      if (
        window.location.pathname === '/email-unverified' ||
        window.location.pathname === '/game_demo' ||
        window.location.pathname === '/'
      ) {
        return;
      }
      await loginWithRedirect();
    }
    if (isLoading || isAuthenticated) return;
    checkLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated])

  //logout function
  const logoutAuth0 = async (clearData) => {
    if (clearData) clearData();
    clearAllData()
    await logout({ logoutParams: { returnTo: window.location.origin } })
  };

  return (
    <Auth0Context.Provider value={{
      user,
      isAuthenticated,
      error,
      isLoading,
      getAccessTokenSilently,
      getWithAccessToken,
      postWithAccessToken,
      patchWithAccessToken,
      putWithAccessToken,
      deleteWithAccessToken,
      logoutAuth0,
      getTokenIfNecessary
    }}>
      {children}
    </Auth0Context.Provider>
  );
};


export default AuthContextProvider;