import React, { useContext, useState } from 'react'

import {
  Box,
  FormControlLabel,
  Stack,
  styled,
  Switch,
  Typography,
  useTheme,
} from '@mui/material'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import {
  Dialog,
} from '@mui/material'
import { Button, Colors, Textfield, ErrorBanner } from 'helika-ui-sdk'
import UploadImageComponent from './UploadImageComponent'
import { createNewOffer } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { useSelector } from 'react-redux'
import _, { debounce } from 'lodash'
import { isStringEmpty, prependUrlProtocolIfNecessary } from 'src/utils/string'
import { toast } from 'react-toastify'
import { isValidURL } from 'src/utils/validators'

export interface SimpleDialogProps {
  open: boolean
  setOpen: any
  onCreateOffer: any
}

export interface Dashboard_mapping {
  name: string
  iframe_link: string
}


export const Android12Switch: any = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      "+ .MuiSwitch-track": {
        backgroundColor: `${Colors.brandColors.solid} !important`,
        opacity: 1,
      },
      ".MuiSwitch-thumb": {
        backgroundColor: Colors.blackAndWhite.white,
      },
      "&.Mui-checked": {
        transform: "translateX(1.5em)",
      },
    },
    height: '3em'
  },
  "&.Mui-disabled + .MuiSwitch-track": {
    backgroundColor: "black"
  },
  '& .MuiSwitch-track': {
    borderRadius: '100vmax',
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
    },
    height: '2em',
    backgroundColor: `${Colors.brandColors.solid} !important`,
    opacity: 0.5,
    color: Colors.brandColors.solid
  },
  "& .Mui-checked": {
    color: Colors.blackAndWhite.white
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: Colors.blackAndWhite.white,
    boxShadow: 'none',
    width: '2em',
    height: '2em',
    margin: 2,
  },
  '&:before': {
    left: '1em',
  },
  '&:after': {
    right: '1em',
  },
  width: '5em',
  height: '3em',
}));

const CreateOfferModal = (props: SimpleDialogProps) => {
  const { open, setOpen, onCreateOffer } = props
  const theme = useTheme()
  const { postWithAccessToken } = useContext(Auth0Context)
  const { organization_id, id } = useSelector((state: any) => state.user)

  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [url, setUrl] = useState<string>('');
  const [isActive, setIsActive] = useState<boolean>(true);
  const [image, setImage] = useState<any>();
  const [storedImageId, setStoredImageId] = useState<string | undefined>();
  const [missingDetails, setMissingDetails] = useState<boolean>(false)

  const [loading, setLoading] = useState<boolean>(false);

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value)
  }
  const handleChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value)
  }
  const handleChangeUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const debouncedClickHandler = debounce(createOfferApi, 200, { maxWait: 500 })

  async function resetFields() {
    setTitle('')
    setUrl('')
    setDescription('')
    setIsActive(true)
    setImage(undefined)
    setStoredImageId(undefined)
    setMissingDetails(false)
  }

  async function createOfferApi() {

    try {
      if (loading) return
      setLoading(true)

      try {
        isStringEmpty(title, 'Title required')
        isStringEmpty(url, 'Button URL required')
      } catch (e: any) {
        setMissingDetails(true)
        throw new Error(e)
      }
      if (!isValidURL(url)) {
        setLoading(false)
        toast.error('Button URL is an invalid URL')
        return;
      }

      let args: any = {
        title: title,
        body: description,
        cta_url: prependUrlProtocolIfNecessary(url),
        organization_id: organization_id,
        creator: id,
        start: new Date(),
        end: null,
        active: isActive
      }

      //todo: add image upload when backend is ready
      // let newUUID = v4();
      // //save campaign image
      // if (image && image !== null) {
      //   let uploadURL = await new Common_Query().uploadImage(postWithAccessToken, {
      //     File: image,
      //     upload_type: 'project_image',
      //     upload_prefix: `${response.data.id}_${newUUID}`
      //   }).catch(e => {
      //     toast.error('Could not upload image')
      //     setLoading(false);
      //     return;
      //   });
      //   if (!uploadURL) {
      //     setLoading(false);
      //     console.error('Could not get upload URL from s3 for Campaign image');
      //   } else {
      //     setLoading(false);
      //   }
      // }

      await createNewOffer(
        postWithAccessToken,
        args
      ).then((resp: any) => {
        if (!_.isEmpty(resp?.results?.offers)) {
          onCreateOffer(resp?.results?.offers)
          setOpen(false)
          setLoading(false)
          setMissingDetails(false)
          resetFields()
        } else {
          throw new Error('Could not create offer')
        }
      })
    } catch (e: any) {
      toast.error(e)
      setLoading(false)
    }

  }

  return (
    <Dialog
      disableRestoreFocus
      fullScreen={true}
      className="defaultModalStyle"
      sx={{ margin: 'auto' }}
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          color: theme.palette.mode === 'dark' ? 'white' : 'black',
          background: theme.palette.mode === 'dark' ? Colors.background.primary : 'white',
          borderStyle: 'solid',
          borderColor: '#404040',
          borderWidth: '1px',
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
          minWidth: '30em',
          padding: '1em'
        },
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '2em'
        }}
      >
        <Typography variant={'h6'}>Create New Offer</Typography>
        <Box
          style={{ margin: 'auto 0 auto 0', cursor: 'pointer' }}
          onClick={handleClose}
        >
          X
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2em'
        }}
      >
        <ErrorBanner
          show={missingDetails && (_.isEmpty(title) || _.isEmpty(url))}
          errorMessage='Fill out missing offer details.'
        />
        <Textfield
          label={'Title'}
          value={title}
          onChange={handleChangeTitle}
          hasError={(missingDetails && _.isEmpty(title?.trim()))}
          placeHolder='Enter title'
        />
        <Textfield
          label={'Button URL'}
          value={url}
          onChange={handleChangeUrl}
          hasError={(missingDetails && _.isEmpty(url?.trim()))}
          placeHolder='https://'
        />
        <Stack direction="column" gap={1}>
          <Box sx={{ fontSize: '0.9em' }}>Image (Optional)</Box>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <UploadImageComponent
              image={image}
              setImage={setImage}
              storedImageId={storedImageId}
              setStoredImageId={setStoredImageId}
              disabled={false}
            />
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                justifyContent: 'center',
                padding: '1em',
                color: Colors.blackAndWhite.grey200
              }}
            >
              <Box>Recommended size:</Box>
              <Box>250x250:</Box>
            </Box>
          </Box>
        </Stack>
        <Textfield
          label={'Description (Optional)'}
          value={description}
          onChange={handleChangeDescription}
          hasError={false}
          isTextBox={true}
          placeHolder='Enter description'
        />
        <Stack direction="row" gap={1}>
          <FormControlLabel
            control={
              <Android12Switch
                checked={isActive}
                onClick={() => {
                  setIsActive(!isActive)
                }}
              />}
            label={<Box sx={{ padding: '0.1em 0 0 0.5em' }}>Offer Status is {isActive ? 'Active' : 'Inactive'}</Box>}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
          gap: '1em'
        }}
      >
        <Button
          sxProps={{
            background: 'transparent',
            width: '6em'
          }}
          handleClick={() => { setOpen(false) }}
        >
          Cancel
        </Button>
        <Button
          sxProps={{
            width: '6em'
          }}
          disabled={loading}
          handleClick={debouncedClickHandler}
        >
          Create
        </Button>
      </Box>
    </Dialog>
  )
}

export default CreateOfferModal
