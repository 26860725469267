import React, { FC, useState, useEffect, useContext } from 'react'
import {
  Box,
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  Stack,
  IconButton,
  InputAdornment,
} from '@mui/material'
import {
  Close as CloseIcon,
  ErrorOutline as ErrorIcon,
} from '@mui/icons-material'
import { styled } from '@mui/system'
import { postNewCampaign, updateCampaign } from 'src/utils/api/queries'
import { Auth0Context } from 'src/contexts/Auth0Context'
import { toast } from 'react-toastify'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

// StatusDot component for status indicator
const StatusDot = styled('div')<{ color: string }>(({ color }) => ({
  width: '8px',
  height: '8px',
  borderRadius: '50%',
  backgroundColor: color,
  marginRight: '6px',
}))

// Status colors and labels mapping
const statusColors: { [key: string]: string } = {
  not_started: '#1A74A8', // Blue
  active: '#067A57', // Green
  finished: '#9654F0', // Purple
  archived: '#545454', // Gray
}
const statusLabels: { [key: string]: string } = {
  not_started: 'Not Started',
  active: 'Active',
  finished: 'Finished',
  archived: 'Archived',
}

interface CampaignModalProps {
  open: boolean
  onClose: (refreshTable: boolean) => void
  isEdit: boolean
  campaignData?: {
    id: number
    name?: string
    startDate?: string
    endDate?: string
    status?: string
  }
}

const CampaignModal: FC<CampaignModalProps> = ({
  open,
  onClose,
  isEdit,
  campaignData,
}) => {
  const { postWithAccessToken, putWithAccessToken } = useContext(Auth0Context)

  // Form state variables
  const [name, setName] = useState<string>(campaignData?.name || '')
  const [startDate, setStartDate] = useState<string>(
    campaignData?.startDate || '',
  )
  const [endDate, setEndDate] = useState<string>(campaignData?.endDate || '')
  const [status, setStatus] = useState<string>(
    campaignData?.status || 'not_started',
  )
  const [isSaving, setIsSaving] = useState<boolean>(false)

  // Error states for individual inputs
  const [nameError, setNameError] = useState(false)
  const [startDateError, setStartDateError] = useState(false)
  const [endDateError, setEndDateError] = useState(false)

  // Initialize form values when opening modal in edit mode
  useEffect(() => {
    if (isEdit && campaignData) {
      setName(campaignData.name || '')
      setStartDate(campaignData.startDate || '')
      setEndDate(campaignData.endDate || '')
      setStatus(campaignData.status || 'not_started')
    } else if (!isEdit) {
      resetForm() // Reset form when modal is opened for creating new campaign
    }
  }, [campaignData, isEdit])

  // Function to reset form inputs and error states
  const resetForm = () => {
    setName('')
    setStartDate('')
    setEndDate('')
    setStatus('not_started')
    setNameError(false)
    setStartDateError(false)
    setEndDateError(false)
  }

  // Validate the form inputs individually
  const validateForm = () => {
    const isNameValid = !!name.trim()
    const isStartDateValid = !!startDate
    const isEndDateValid = !!endDate

    setNameError(!isNameValid)
    setStartDateError(!isStartDateValid)
    setEndDateError(!isEndDateValid)

    return isNameValid && isStartDateValid && isEndDateValid
  }

  // Handle the Save action
  const handleSave = () => {
    // Format dates before making API request
    const formattedStartDate = dayjs(startDate).format(
      'YYYY-MM-DDTHH:mm:ss.SSSZ',
    )
    const formattedEndDate = dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ')

    if (validateForm()) {
      if (isEdit) {
        updateExistingCampaign(formattedStartDate, formattedEndDate)
      } else {
        postCampaign(formattedStartDate, formattedEndDate)
      }
    }
  }

  // Function to post a new campaign
  const postCampaign = async (
    formattedStartDate: string,
    formattedEndDate: string,
  ) => {
    const campaignPayload = {
      name,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      status,
    }

    try {
      setIsSaving(true)
      await postNewCampaign(postWithAccessToken, campaignPayload)
      toast.success('Campaign created successfully!')
      handleModalClose(true)
    } catch (e: any) {
      toast.error(e?.message || 'Failed to create campaign')
      setIsSaving(false)
    }
  }

  // Function to update an existing campaign
  const updateExistingCampaign = async (
    formattedStartDate: string,
    formattedEndDate: string,
  ) => {
    if (!campaignData || !campaignData.id) {
      toast.error('Invalid campaign data. Cannot update.')
      return
    }

    const campaignPayload = {
      id: campaignData.id,
      name,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      status,
    }

    try {
      setIsSaving(true)
      await updateCampaign(putWithAccessToken, campaignData.id, campaignPayload)
      toast.success('Campaign updated successfully!')
      handleModalClose(true)
    } catch (e: any) {
      toast.error(e?.message || 'Failed to update campaign')
      setIsSaving(false)
    }
  }

  // Handle closing the modal and reset the form
  const handleModalClose = (success = false) => {
    resetForm() // Clear inputs and error states on close
    setIsSaving(false)
    onClose(success)
  }

  // Handle field changes and remove individual validation errors
  const handleFieldChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    setError: React.Dispatch<React.SetStateAction<boolean>>,
    value: string,
  ) => {
    setter(value)
    if (value) {
      setError(false) // Clear the error for the specific field
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        disableRestoreFocus
        open={open}
        onClose={() => handleModalClose(false)}
        PaperProps={{
          sx: {
            width: '420px',
            height: 'fit-content',
            maxHeight: 'none',
            color: 'white',
            background: '#141414',
            borderRadius: '8px',
            filter: 'drop-shadow(0px 20px 20px rgba(0, 0, 0, 0.3))',
          },
        }}
      >
        {/* Modal Header */}
        <Box
          sx={{
            padding: '16px 24px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: '#141414',
            borderRadius: '8px 8px 0 0',
          }}
        >
          <Typography
            sx={{ fontWeight: 600, fontSize: '20px', color: '#FFFFFF' }}
          >
            {isEdit ? 'Edit Campaign' : 'Create Campaign'}
          </Typography>
          <IconButton
            onClick={() => handleModalClose(false)}
            sx={{ color: '#FFFFFF' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Modal Content */}
        <Box sx={{ padding: '24px', background: '#141414' }}>
          {(nameError || startDateError || endDateError) && (
            <Box
              sx={{
                marginBottom: 2,
                background: 'rgba(199, 58, 58, 0.1)',
                borderLeft: '3px solid #C73A3A',
                borderRadius: '4px',
                padding: '12px 16px',
              }}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <ErrorIcon sx={{ color: '#C73A3A' }} />
                <Typography sx={{ color: '#FFFFFF' }}>
                  Fill out missing campaign details.
                </Typography>
              </Stack>
            </Box>
          )}

          <Grid container spacing={2}>
            {/* Name Field */}
            <Grid item xs={12}>
              <Typography sx={{ color: '#FFFFFF' }}>Name</Typography>
              <TextField
                value={name}
                onChange={(e) =>
                  handleFieldChange(setName, setNameError, e.target.value)
                }
                fullWidth
                placeholder="Campaign name"
                InputProps={{
                  endAdornment: nameError && (
                    <InputAdornment position="end">
                      <ErrorIcon sx={{ color: '#C73A3A' }} />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  backgroundColor: '#1C2025',
                  borderRadius: '4px',
                }}
                error={Boolean(nameError)}
              />
            </Grid>

            {/* Start Date Field */}
            <Grid item xs={12}>
              <Typography sx={{ color: '#FFFFFF' }}>Start Date</Typography>
              <DatePicker
                views={['year', 'month', 'day']}
                value={dayjs(startDate)}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  handleFieldChange(
                    setStartDate,
                    setStartDateError,
                    dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                  )
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                    InputProps: {
                      sx: {
                        backgroundColor: '#1C2025',
                        borderRadius: '4px',
                      },
                    },
                    error: Boolean(startDateError),
                    helperText: startDateError ? 'Start date is required' : '',
                  },
                }}
              />
            </Grid>

            {/* End Date Field */}
            <Grid item xs={12}>
              <Typography sx={{ color: '#FFFFFF' }}>End Date</Typography>
              <DatePicker
                views={['year', 'month', 'day']}
                value={dayjs(endDate)}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  handleFieldChange(
                    setEndDate,
                    setEndDateError,
                    dayjs(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                  )
                }
                slotProps={{
                  textField: {
                    fullWidth: true,
                    InputProps: {
                      sx: {
                        backgroundColor: '#1C2025',
                        borderRadius: '4px',
                      },
                    },
                    error: Boolean(endDateError),
                    helperText: endDateError ? 'End date is required' : '',
                  },
                }}
              />
            </Grid>

            {/* Status Field */}
            <Grid item xs={12}>
              <Typography sx={{ color: '#FFFFFF' }}>Status</Typography>
              <Select
                value={status}
                onChange={(e) => setStatus(e.target.value as string)}
                fullWidth
                sx={{
                  backgroundColor: '#1C2025',
                  borderRadius: '4px',
                  color: '#FFFFFF',
                }}
                disabled={isSaving}
              >
                {Object.keys(statusColors).map((statusKey) => (
                  <MenuItem key={statusKey} value={statusKey}>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <StatusDot color={statusColors[statusKey]} />
                      <Typography>{statusLabels[statusKey]}</Typography>
                    </Stack>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>

        {/* Modal Footer */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '16px 24px',
            background: '#141414',
            borderRadius: '0 0 8px 8px',
          }}
        >
          <Button
            onClick={() => handleModalClose(false)}
            sx={{
              minWidth: '110px',
              height: '36px',
              color: '#FFFFFF',
              backgroundColor: 'transparent',
              border: 'none',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              marginRight: '8px',
            }}
            disabled={isSaving}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            sx={{
              minWidth: '110px',
              height: '36px',
              backgroundColor: '#EA335F',
              color: '#FFFFFF',
              borderRadius: '4px',
            }}
            disabled={isSaving}
          >
            {isEdit ? 'Save' : 'Create'}
          </Button>
        </Box>
      </Dialog>
    </LocalizationProvider>
  )
}

export default CampaignModal
